import * as React from "react";
import { Component } from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import { RegionTableProps, RegionTableComponent } from "./components/regiontable";
import { lazyInject, TYPES, ListStates, GSAdminAction, InvitationHelper, fmtMsg } from "@app/util/index";
import { IRegionGroupService } from "@app/service/admin/regiongroup/index";
import { IRegionService } from "@app/service/admin/regions/interface";
import { RegionModel, GetRegionRequest, RegionModelPropNames } from "@app/service/admin/regions/model";
import { RegionGroupCreationModal } from "./components/regiongroupoperation";
import { IContentVersionService, ContentVersionModel } from "@app/service/admin/contentversion/index";
import { withRouter, ResourceOperation, PaginationParams, GLUtil, GLAction, GLGlobal } from "gl-commonui";
import { PathConfig } from "@app/config/pathconfig";
import { ListStatesStore, ListStatesRadios, MainTitle, Container } from "@app/components";
import { Row, Col, Icon } from "antd-min";
import { GSAdminLocale } from "@app/locales/localeid";
import { GLGridSorter } from "@app/components/gl-grid";

interface RegionsPageProps extends RouteComponentProps<any> {}
interface RegionsPageStates {
    loading?: boolean;
    resCmd?: ResourceOperation;
    listState: ListStates;
    visible?: boolean;
    disabled?: boolean;
}
@withRouter
export class RegionsPage extends Component<RegionsPageProps, RegionsPageStates> {
    @lazyInject(TYPES.IRegionService)
    service: IRegionService;
    @lazyInject(TYPES.IContentVersionService)
    contentVersionService: IContentVersionService;
    @lazyInject(TYPES.IRegionGroupService)
    regionGroupService: IRegionGroupService;
    pagination: PaginationParams = new PaginationParams(1, 20);
    contentversions: ContentVersionModel[];
    regionModal = null;
    sorter: GLGridSorter = {
        columnName: RegionModelPropNames.englishName, //RegionModelPropNames.name,
        columnSortName: RegionModelPropNames.englishName, //RegionModelPropNames.name,
        ascending: true,
    };
    tableProps: RegionTableProps = {
        sorter: this.sorter,
        handleTableSorterChange: this.handleTableSorterChange.bind(this),
        handleTableChange: this.handleTableChange.bind(this),
        resOpers: {
            default: () => {
                this.regionModal = null;
                this.setState({ resCmd: ResourceOperation.default });
                this.setRegionList();
            },
            create: () => {},
            update: (value) => {
                this.props.history.push({ pathname: GLUtil.pathStringify(PathConfig.RegionEdit, { regionId: value.id }) });
            },
            delete: () => {},
            select: (value) => {},
        },
    };
    regionList?: RegionModel[] = [];
    listStateStore: ListStatesStore;
    listCnts: any = {};
    constructor(props) {
        super(props);
        this.listStateStore = new ListStatesStore("regions-list-states", "regions-list-states");
        this.state = {
            listState: this.listStateStore.getState(),
        };
    }
    async componentDidMount() {
        this.contentversions = await this.initContentVersions();
        this.setRegionList(this.sorter);
    }

    setTableLoading(isLoading: boolean = true) {
        this.tableProps.loading = isLoading;
        this.setState({ loading: isLoading });
    }
    handleTableChange(pagination: any) {
        this.pagination.current = pagination.current;
        this.setRegionList(this.sorter);
    }
    handleTableSorterChange(sorter: GLGridSorter) {
        this.sorter = sorter;
    }

    setRegionList(sorter?: GLGridSorter) {
        const params = this.listStateStore.getUrlQuery(new GetRegionRequest(this.pagination));
        const sortBy = sorter ? { sortBy: sorter.columnSortName, isDescending: !sorter.ascending } : null;
        this.setTableLoading();
        this.service.getItemsBy({ ...params, detail: true, sortBy }).then((data) => {
            this.listCnts.allCnt = data.totalCount;
            this.listCnts.activeCnt = data.extraData.activeCnt;
            this.tableProps.pagination = this.pagination;
            this.tableProps.pagination.total = ListStatesStore.getStateCnt(data.totalCount, data.extraData.activeCnt, this.state);
            this.tableProps.dataSource = this.service.replaceContentversions(data.data, this.contentversions);
            this.setTableLoading(false);
        });
    }

    initContentVersions() {
        return this.contentVersionService.get<ContentVersionModel[]>();
    }

    createRegionGroup() {
        const modalTitle = fmtMsg({ id: GSAdminLocale.RegionListGroup });
        return (
            <RegionGroupCreationModal
                title={modalTitle}
                disabled={this.state.disabled}
                visible={this.state.visible}
                onSubmit={(_self, value) => {
                    this.setState({ disabled: true });
                    this.regionGroupService
                        .createRegionGroup({ name: value.name })
                        .then((data) => {
                            this.setState({
                                visible: false,
                                disabled: false,
                            });
                            _self.props.form.resetFields();
                            InvitationHelper.sendEmailSuccess(fmtMsg({ id: GSAdminLocale.RegionListSuccessGroup }));
                        })
                        .catch((error) => {
                            this.setState({ disabled: false });
                            InvitationHelper.sendEmailFail(fmtMsg({ id: GSAdminLocale.RegionListFailGroup }));
                        });
                }}
                onCancel={() => {
                    this.setState({ visible: false });
                }}
            />
        );
    }

    render() {
        return (
            <Container fullWidth className="reg-page">
                <MainTitle plain={fmtMsg({ id: GSAdminLocale.RegionListTitle })} />
                <Row className="reg-page__head" type="flex" justify="space-between">
                    <Col>
                        <ListStatesRadios
                            allCnt={this.listCnts.allCnt}
                            activeCnt={this.listCnts.activeCnt}
                            value={this.state.listState}
                            onChange={(state) => {
                                this.setState({ listState: state });
                                this.listStateStore.setState(state);
                                this.handleTableChange(new PaginationParams(1, 20));
                            }}
                        />
                    </Col>
                    <Col>
                        <GLAction action={GSAdminAction.CreateRegion}>
                            <Col>
                                <GLAction action={GSAdminAction.RegionGroup}>
                                    <a
                                        className="reg-page__action"
                                        onClick={() => {
                                            this.setState({ visible: true });
                                        }}
                                    >
                                        <Icon type="plus-circle" />
                                        <span>{fmtMsg({ id: GSAdminLocale.RegionListNewGroup })}</span>
                                    </a>
                                </GLAction>
                                <Link className="reg-page__action" to={{ pathname: PathConfig.RegionCreate }}>
                                    <Icon type="plus-circle" className="add-region" />
                                    <span>{fmtMsg({ id: GSAdminLocale.RegionListNewRegion })}</span>
                                </Link>
                            </Col>
                        </GLAction>
                    </Col>
                </Row>
                <RegionTableComponent {...this.tableProps} history={this.props.history}></RegionTableComponent>
                {this.createRegionGroup()}
            </Container>
        );
    }
}
