import * as React from 'react'
import { Component } from 'react'
import { Select, Modal, Form, Input, Button } from "antd-min";
import { RegionGroupPropNames } from '../../../../service/admin/regiongroup/index';
import { GLFormComponentProps, GLForm, FormHelper, FormItemsLayout, FormItemInModalParams, GLGlobal } from 'gl-commonui';
import { RegionModel } from '../../../../service/admin/regions/model';
import { GSAdminLocale } from '../../../../locales/localeid';
import { fmtMsg } from '../../../../util/index';

export interface RegionGroupOperationProps {
    model?: RegionModel
    width?: string
    disabled?: boolean
    onSubmit?: (_self, value: any) => void
    onCancel?: () => void
}
interface RegionGroupOperationStates {
    visible?: boolean
    defaultTemplate?: string
    templateOptions?: JSX.Element[] | null
}
export abstract class RegionGroupOperationModal<TProps, TStates> extends Component<TProps & RegionGroupOperationProps & GLFormComponentProps, TStates & RegionGroupOperationStates> {
    width?: any
    protected modalTitle?: string;
    protected footer?: JSX.Element[] | null;
    constructor(props, context) {
        super(props, context);
    }

    initState(selfState = { visible: false } as RegionGroupOperationStates & TStates) {
        return { ...selfState as object } as RegionGroupOperationStates & TStates;
    }

    getOption(enumObj, value) {
        return <Select.Option key={value} value={value}>{enumObj}</Select.Option>
    }
    validateFields() {
        let variables = null;
        this.props.form.validateFieldsAndScroll(null, {}, (err, values: RegionModel) => {
            if (!err) {
                this.props.model && (values.id = this.props.model.id)
                variables = this.props.form.getFieldsValue();
            }
        });
        return variables;
    }
    onOk() {
        const variables = this.validateFields();
        if (variables !== null && this.props.onSubmit) {
            this.props.onSubmit(this, variables);
            this.setState({ visible: false });
        }
    }
    onCancel() {
        this.setState({ visible: false });
        this.props.onCancel();
        const self = this;
        setTimeout(function(this) {
            self.props.form.resetFields();
        }, 0);
    }
    abstract renderContent()
    render() {
        const props = {
            visible: this.state.visible,
            title: this.modalTitle,
            onOk: this.onOk.bind(this),
            onCancel: this.onCancel.bind(this),
            wrapClassName: "vertical-center-modal",
            width: this.width || "780px",
            footer: this.footer
        };
        return <Modal {...props}>
            {this.renderContent()}
        </Modal>;
    }
}

@GLForm.create()
export class RegionGroupCreationModal extends RegionGroupOperationModal<any, any> {
    modalprops
    constructor(props, context) {
        super(props, context);
        this.state = this.initState();
        this.modalTitle = GSAdminLocale.RegionListGroup;
    }

    componentWillReceiveProps(nextProps: any, nextContext: any) {
        this.props.visible != nextProps.visible && this.setState({ visible: nextProps.visible })
    };
    
    renderContent() {
        const form = this.props.form;
        const { renderFormItem } = FormHelper;
        const regionNameParams: FormItemInModalParams = {
            form: form,
            localeId: GSAdminLocale.RegionGroupName,
            fieldName: RegionGroupPropNames.name,
            formControl: <Input />,
            required: true,
        }
        return (
            <Form  >
                <FormItemsLayout colTotal={1}>
                    {renderFormItem(regionNameParams)}
                </FormItemsLayout>
            </Form>
        )
    }

    renderFooter() {
        this.modalprops.footer = [
            <Button key="cancel" onClick={() => this.onCancel()}>{fmtMsg({id:GSAdminLocale.ButtonCancel})}</Button>,
            <Button key="invite" type="primary" disabled={this.props.disabled} onClick={() => this.onOk()}>{fmtMsg({id:GSAdminLocale.ButtonOk})}</Button>,
          ];
    }

    render() {
        this.modalprops = {
            visible: this.props.visible,
            destroyOnClose: true,
            title: this.props.title? this.props.title : fmtMsg({id: this.modalTitle}),
            onOk: this.onOk.bind(this),
            onCancel: this.onCancel.bind(this),
            wrapClassName: "vertical-center-modal",
            width: "480px",
        };
        this.renderFooter();
        return <Modal {...this.modalprops}>
            {this.renderContent()}
        </Modal>;
    }
}