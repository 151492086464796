import * as React from 'react'
import { Component } from 'react';
import { Table, Row, Col } from "antd-min";
import { PathConfig } from "@app/config/pathconfig";
import { GLGlobal, PaginationParams, IResourceOperation, GLUtil, GLAction } from 'gl-commonui';
import { RegionModel, RegionModelPropNames } from '@app/service/admin/regions/model';
import { GSAdminLocale } from '@app/locales/localeid';
import { GLGrid, GLGridSorter } from '@app/components/gl-grid';
import { GSAdminAction } from '@app/util';
import './regiontable.less'

export interface RegionTableProps {
    onlyView?: boolean
    loading?: boolean
    dataSource?: RegionModel[]
    pagination?: PaginationParams
    sorter: GLGridSorter
    handleTableSorterChange?: (sorter: GLGridSorter) => void
    handleTableChange?: (pagination: any) => void
    resOpers?: IResourceOperation<RegionModel>
    history?: any
}
interface RegionTableStates {}

export class RegionTableComponent extends Component<RegionTableProps, RegionTableStates> {
    formatMessage = GLGlobal.intl.formatMessage;
    columnSortingMap = new Map<string, string>([
        [ RegionModelPropNames.gsContentversion, 'gsVersionGroupId' ],
        [ RegionModelPropNames.lsContentversion, 'lsVersionGroupId' ]
    ]);
    tableLayout: any = {
        rowKey: 'id',
        columns: [
            {
                title: GSAdminLocale.RegionsModelName,
                dataIndex: RegionModelPropNames.englishName,
                render: (text, region, index) => {
                    if (GLGlobal.isActionValid(GSAdminAction.ListRegion)) {
                        return `<a href="${GLUtil.pathStringify(PathConfig.Region , { regionId: region.id })}" > <span>${text}</span> </a>`
                    }
                    else {
                        return `<span>${text}</span>`;
                    }
                },
                className: "regioncol",
                width: "30%"
            }, {
                title: GSAdminLocale.RegionModelGSContentVersion,
                dataIndex: RegionModelPropNames.gsContentversion,
                width: "10%",
            }, {
                title: GSAdminLocale.RegionModelLSContentVersion,
                dataIndex: RegionModelPropNames.lsContentversion,
                width: "10%",
            }, {
                title: GSAdminLocale.RegionBillingDay,
                dataIndex: RegionModelPropNames.billingDay,
                align: 'left',
                width: "10%",
            }, {
                title: GSAdminLocale.RegionSupportEmail,
                dataIndex: RegionModelPropNames.supportemail,
                width: "20%"
            }, {
                title: GSAdminLocale.RegionMaterialOrderEmail,
                dataIndex: RegionModelPropNames.materialorderemail,
                width: "20%"
            }
        ],
        bordered: false,
        onChange: (pagination) => this.props.handleTableChange(pagination),
        rowClassName: (record) => record.disabled ? "list-disabled" : "",
        onRow: (record: RegionModel, index) => {
            this.props.resOpers.select(record);
        }     
    };

    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    onColumnSorting(sorter: GLGridSorter) {
        const columnName = this.columnSortingMap.has(sorter.columnSortName) ? this.columnSortingMap.get(sorter.columnSortName) : sorter.columnSortName;
        this.props.handleTableSorterChange({...sorter, columnSortName: columnName });
        this.props.handleTableChange(this.tableLayout.pagination);
    }

    renderView() {
        const { dataSource, pagination, sorter } = this.props;
        this.tableLayout.dataSource = dataSource;
        this.tableLayout.pagination = pagination;
        this.tableLayout.loading = this.props.loading;
        return <GLGrid 
            {...this.tableLayout}
            dataSource = {dataSource? dataSource : []}
            defaultSorter = {sorter}
            onColumnSorting = {this.onColumnSorting.bind(this)}
        />;
    }

    render() {
        return <>
            <Row><Col>{this.renderView()}</Col></Row>
        </>;
    }
}
